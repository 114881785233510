import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClickNavega(incremento: number ) {
    console.log(this.router.config)  

    let  index = this.router.config.indexOf(this.router.config.find(i =>  "/" +i.path.toLowerCase() === this.router.url)) + incremento;

    if (index  > 4)
      index = 1
    else if (index  <= 0)
      index = 4


    this.router.navigate(["/" +this.router.config[index].path.toLowerCase()]);

  }

}
