<div>
  <mat-toolbar color="primary" class="mat-elevation-z6">
    <button class="navbar-brand" href="#" style="height: 8vh;width: 40vh; max-width:50%; background-color: black;border: none;" >
      <img
      width="100%"
      height="100%"
      alt="Angular Logo"
      src="../assets/method-draw-image.svg"
      /></button>
    
    <span class="fill-space"></span>
    <div fxShow="true" fxHide.lt-md="true" class="menu">
      <a mat-button routerLink="/home" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Home</a>
      <a mat-button routerLink="/servicos" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Serviços</a>
      <a mat-button routerLink="/empresa" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Empresa</a>
      <a mat-button routerLink="/contato" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Contato</a>
    </div>
    <div fxShow="true" fxHide.gt-sm="true" > 
      <button mat-icon-button (click)="sidenav.toggle()" style="background-color:black">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

  </mat-toolbar>
  <button mat-mini-fab color="primary" class="botao-anterior"  (click)="onClickNavega(-1)">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <button mat-mini-fab color="accent" class="botao-proximo" (click)="onClickNavega(1)">
    <mat-icon class>chevron_right</mat-icon>
  </button>
  <mat-sidenav-container fxFlexFill class="example-container">

    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
      <div fxLayout="column">
        <a mat-button routerLink="/home" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Home</a>
        <a mat-button routerLink="/servicos" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Serviços</a>
        <a mat-button routerLink="/empresa" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Empresa</a>
        <a mat-button routerLink="/contato" routerLinkActive="pagina-atual" routerLinkActiveOptions="{exact:true}">Contato</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill >
    

      <router-outlet></router-outlet>      
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<!--<mat-toolbar color="primary" class="mat-elevation-z6">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="navbar-brand" href="#">
        <img
        width="300"
        height="46"
        alt="Angular Logo"
        src="../assets/method-draw-image.svg"
        /></a>
    <span class="example-spacer"></span>  
    <span class="fill-space"></span>
    <a href="./home"  mat-flat-button >Home</a>
    <a href="./servicos"  mat-flat-button>Serviços</a>
    <a href="./empresa"  mat-flat-button>Empresa</a>
    <a href="./contato"  mat-flat-button>Contato</a>
   

  </mat-toolbar>
-->