<div flex fxLayout="row" fxLayout.lt-sm="column">
  <div  fxFlex="50">
    <div flex fxLayout="row">
      <div  fxFlex="100">
        <div class="titulo">Contato</div>
      </div>
    </div>
    <div flex fxLayout="row">
      <div class="conteudo" fxFlex="100">
        <mat-icon style="vertical-align: middle" aria-hidden="false" class="fone-icon">phone</mat-icon>(42) 99830-9300
        <br>
        <mat-icon style="vertical-align: middle" aria-hidden="false" class="fone-icon">phone</mat-icon>(42) 3232-3177
        <br>
        <mat-icon  style="vertical-align: middle" aria-hidden="false" class="fone-icon">mail</mat-icon>Defense2016@outlook.com.br 
        <br>
      </div>
    </div>
    <div  fxLayout="row" style="text-align: center;justify-content: center;">
      
        <a href="https://web.facebook.com/Defense-monitoramento-555027111360811/" style="font-size: 100px;" class="facebook-icon"
          title="acessar o Facebook" style="padding-bottom: 7px">
          <img src="https://upload.wikimedia.org/wikipedia/commons/c/c2/F_icon.svg" height="85px"/>
        </a>
      
        <a href="https://instagram.com/defensemonitoramento?igshid=YmMyMTA2M2Y=" style="font-size: 100px;" title="acessar o Instagram">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" height="85px"
             />
        </a>
      
        <a href="https://api.whatsapp.com/send?phone=5542998309300" style="font-size: 100px;"
          title="acessar o Whatsapp">
          <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" height="100px" />
        </a>
   
    </div>
  </div>
  <div  fxFlex="50">
    <div flex fxLayout="row">
      <div  fxFlex="100">
        <div class="titulo">Endereço</div>
      </div>
    </div>
    <div flex fxLayout="row">
      <div class="conteudo" fxFlex="100">
        Rua Maestro Benedito Pereira, 717 - Centro, Castro - PR, 84165-240
      </div>
    </div>
    <div flex fxLayout="row">
      <div class="conteudo" fxFlex="100">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d905.5307444786653!2d-50.0123781788846!3d-24.791242156650917!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ebc3d96927adb28!2sDefense%20Monitoramento!5e0!3m2!1spt-BR!2sbr!4v1608838354724!5m2!1spt-BR!2sbr"
          width="300" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
          tabindex="0">
        </iframe>
      </div>
    </div>
  </div>
</div>




<!--
<div class="background" flex fxLayout="row"  fxLayout.lt-sm="column">
  
    <mat-card-content  class="conteudo"  fxFlex="50" > 
            <mat-card-title class="titulo">Contato</mat-card-title>
          <br>
          <mat-icon style="vertical-align: middle" aria-hidden="false" class="fone-icon">phone</mat-icon>(42) 3232-3177
          <br>
          <mat-icon style="vertical-align: middle" aria-hidden="false" class="fone-icon">phone</mat-icon>(42) 99830-9300
          <br>
          <mat-icon  style="vertical-align: middle" aria-hidden="false" class="fone-icon">mail</mat-icon>Defense2016@outlook.com.br 
          <br>

          <a href="https://web.facebook.com/Defense-monitoramento-555027111360811/" style="font-size: 100px;"
            title="acessar o Facebook" style="padding-bottom: 7px">
            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c2/F_icon.svg" height="85px"  style="padding-bottom: 7px"/>
          </a>

          &nbsp; &nbsp; &nbsp; &nbsp;
          <a href="https://www.instagram.com/leonardodefense/" style="font-size: 100px;" title="acessar o Instagram">
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" height="85px"  style="padding-bottom: 7px"/>
          </a>


          &nbsp; &nbsp; &nbsp; &nbsp;
          <a href="https://api.whatsapp.com/send?phone=5542999445890" style="font-size: 100px;"
            title="acessar o Whatsapp">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" height="100px"  />
          </a>
       
        </mat-card-content>
  

      <mat-card-content class="conteudo" fxFlex="50">
        <mat-card-title class="titulo">Endereço</mat-card-title>
        <p>
          Rua Maestro Benedito Pereira, 717 - Centro, Castro - PR, 84165-240
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d905.5307444786653!2d-50.0123781788846!3d-24.791242156650917!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ebc3d96927adb28!2sDefense%20Monitoramento!5e0!3m2!1spt-BR!2sbr!4v1608838354724!5m2!1spt-BR!2sbr"
          width="300" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
          tabindex="0"></iframe>
      </mat-card-content>

  
</div>
-->
