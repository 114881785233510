import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  slides = [{ 'image': '../assets/Logo6.png' }, 
            { 'image': '../assets/logo2.jpg' }, 
            { 'image': '../assets/logo3.jpg' }, 
            { 'image': '../assets/logo4.jpg' }, 
            { 'image': '../assets/logo5.jpg' }];

  ngOnInit(): void {
  }

}

