<div class="background">
  
  <div flex fxLayout="row" fxLayout.lt-sm="column">
    <div fxFlex="40" style="text-align: center; vertical-align:middle;">
      <img alt="Angular Logo" src="./assets/logo6.jpg" class="imagem" />
    </div>
    <div fxFlex="20"></div>
    <div fxFlex="40" style="vertical-align: middle;">
      <div flex fxLayout="row" fxLayout.lt-sm="column" class="titulo">
        <div fxFlex="100" style="text-align: center; vertical-align:middle;">
          MONITORAMENTO <br> 24 Horas</div>
        </div>
      <div flex fxLayout="row" fxLayout.lt-sm="column">
      <div fxFlex="100"  class="conteudo">
          Alarme monitorado
          <br>Cerca elétrica
          <br>Portão eletrônico
          <br>Interfone
          <br>CFTV
        </div>
      </div>
      <div flex fxLayout="row" fxLayout.lt-sm="column" > 
        <div fxFlex="100"  style="text-align: center; vertical-align:middle;">
        <a href="https://api.whatsapp.com/send?phone=5542998309300"
        
          class="btn button botaoWpp" style="text-align: center;" title="acessar o Whatsapp">Fale conosco pelo Whatsapp</a>
      </div>
    </div>
    </div>
  </div>
</div>
