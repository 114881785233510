<div class="background">
  <div flex fxLayout="row" fxLayout.lt-sm="column">
    <div fxFlex="100" style="text-align: center; vertical-align:middle;">
      <img alt="Angular Logo" src="./assets/IMG-20201011-WA0237.jpg" class="imagem" />
    </div>
  </div>
    <div flex fxLayout="row" fxLayout.lt-sm="column">
    <div fxFlex="100" style="text-align: center; vertical-align:middle;">
      <div class="titulo">Alarme Monitorado</div>
      <div class="conteudo">
        <p> É um conjunto de equipamentos de alarmes, câmeras e sistemas que tem por finalidade realizar o monitoramento do
          local protegido, através de sensores e imagens das câmeras, pode identificar a violação do perímetro protegido e
          emitir um efeito sonoro e sistêmico para equipe de monitoramento da DEFENSE, que atua 24h.</p>
    
        <p> Possuir esse serviço é um dos meios mais seguros para proteger um local e monitorar acessos não autorizados,
          detectar infratores, incêndios e situações de perigo. O cabeamento é estrategicamente instalado para evitar cortes
          e os alarmes são mantidos por baterias, para funcionarem mesmo com queda de energia, evitando interrupção de funcionamento
          após o corte de um fio e sensores especializados.</p>
    
    
        <ul>
          <strong>Principais Características</strong>
          <li> Os equipamentos são selecionados criteriosamente oferecendo o que há de mais avançado disponível no mercado.
          </li>
          <li> Os alarmes são monitorados 24 horas por dia por equipes treinadas e softwares dedicados à análise e
            reconhecimento de segurança.</li>
          <li> Cada projeto se adequa às necessidades circunstanciais de cada cliente oferecendo um serviço de segurança de
            excelência.</li>
          <li> Os alarmes de monitoramento da Defense Monitoramento atendem desde pequenas residências até enormes
            construções industriais, shoppings e condomínios, oferecendo segurança para todos os públicos sem exceção.</li>
        </ul>
    
      </div>
      <div class="titulo">
        Outros Serviços
      </div>
      <div class="conteudo">
        <ul>
          <li>Cerca elétrica</li>
          <li>Portão eletrônico</li>
          <li>Interfone</li>
          <li>CFTV</li>
        </ul>
      </div>
    </div>
  </div> 
  


</div>
