<div class="background">
  <div class="titulo">Empresa</div>
  <div class="conteudo">
    <p>
      Presente no mercado desde 2010 a DEFENSE é uma empresa líder em sistemas de alarme monitorado que surgiu como uma
      nova opção voltada a oferecer soluções para monitoramento.
    <p>
      Nosso maior objetivo é fornecer soluções especiais para seus clientes afim de que os mesmos possam ter um ambiente
      seja ele de trabalho ou doméstico mais seguro, interativo e organizado.
    </p>

  </div>
  <div class="titulo">
    Missão
  </div>
  <div class="conteudo">
    <p>Proporcionar ambientes seguros com tecnologias de ponta e transparência para que nossos clientes tenham os
      melhores produtos e serviços a sua disposição.</p>

  </div>


</div>
